import React from "react";
import VictoriaLogo from "/src/images/victoria-logo-large.png";
import CityOfMelbourne from "/src/images/city-of-melbourne-logo-large.png";
import CityOfWhittlesea from "/src/images/city-of-whittlesea-logo-large.png";

const GovernmentStats = () => (
  <section className="bg-opacity-10 mx-0 px-6 py-12 sm:py-16 lg:mt-8">
    <div className="mt-5 grid grid-cols-2 md:grid-cols-6 xl:grid-cols-10 gap-10 text-center">
      <div className="col-span-2 xl:col-start-3">
        <img src={VictoriaLogo} alt="Victoria Logo" className="inline h-32" />
        <div className="grid content-center md:h-56 md:bg-white md:shadow-lg rounded">
          <div className="text-6xl font-light p-5" style={{color: '#009579'}}>$160M</div>
          <span className="mx-2 my-auto">Injection of economic stimulus during the Victorian dining & entertainment program campaign</span>
        </div>
      </div>
      <div className="col-span-2">
        <img src={CityOfMelbourne} alt="Melbourne Logo" className="inline h-32" />
        <div className="grid content-center md:h-56 md:bg-white md:shadow-lg md:rounded-lg">
          <div className="text-6xl font-light p-5" style={{color: '#009579'}}>$21.4M</div>
          <span className="mx-2 my-auto">Injection of economic stimulus during the Midweek Melbourne Money & Melbourne Money campaigns</span>
        </div>
      </div>
      <div className="col-span-2">
        <img src={CityOfWhittlesea} alt="Whittlesea Logo" className="inline h-32" />
        <div className="grid content-center md:h-56 md:bg-white md:shadow-lg md:rounded-lg">
          <div className="text-6xl font-light p-5" style={{color: '#009579'}}>$800k</div>
          <span className="mx-2 my-auto">Injection of economic stimulus during the ‘It's All Here’ campaign</span>
        </div>
      </div>
    </div>
  </section>
);

export default GovernmentStats;

