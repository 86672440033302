import React from "react";
import SEO from "@components/seo";
import Layout from "@components/layout";
import Footer from "@components/footers/dark";
import MenuBar from "@components/menuBar/MenuBar";
import FeatureList from "@components/feature-list";
import Hero from "./Hero.js";
import Cta from "@components/Cta";
import Qube from "@components/qube/qube.js";

import Logos from "./Logos"
import MyReceiptRewards from "./features/MyReceiptRewards.js";
import Testimonial from "./features/Testimonial.js";
import CaseStudy from "./features/CaseStudy.js";
import GovernmentStats from "./features/GovernmentStats.js";
import DigitalCard from "./features/DigitalCardAndDigitalApp.js";
import HowItWorks from "./features/HowItWorks.js";


export default function Government() {
  return (
    <>
      <SEO title="Team" />
      <MenuBar/>
      <Layout>
        <Hero/>
        <Logos/>
        <Testimonial/>
        <FeatureList topFade={false} bottomFade={false}>
          <MyReceiptRewards/>
        </FeatureList>
        <GovernmentStats/>
        <HowItWorks/>
        <FeatureList topFade={false} bottomFade={false}>
          <CaseStudy/>
          <DigitalCard/>
        </FeatureList>
        <Qube/>
        <Cta>
          <span>
            WAIVPAY&rsquo;s digital, physical and cashback payments technology
            for instant rewarding, recognition and incentives.
          </span>
        </Cta>
      </Layout>
      <Footer/>
    </>
  );
}
