import React from "react";

const Testimonial = () => {
    return (
    <div className="bg-gray-100">
        
      <div className="max-w-4xl mx-auto py-8 md:py-12 px-4 sm:px-6 lg:px-8">
        <section className="quote-container grid-outer gray x-fade x-fade-down">
            <article className="quote grid">
              <div className="py-4 sm:py-8 text-center">
                <p className="mt-2 text-3xl leading-8 font-semibold tracking-tight text-primary sm:text-4xl">
                  Don't take our word for it...
                </p>
              </div>
                <blockquote className="mt-4 text-lg text-gray-500">
                  "It’s been an absolute pleasure working with the Waivpay team to deliver the Melbourne Money and now Midweek Melbourne Money programs.
                  Waivpay is a highly professional organisation that continues to work collaboratively with the City of Melbourne to deliver this most important economic recovery program.”
                </blockquote>
                <p className="mt-4 text-bold text-lg non-italic">
                  Andrew Wear
                </p>
                <p className="text-lg text-gray-500">Director Economic Development and International City of Melbourne</p>
        </article>
    </section>
    </div>
    </div>
    )
};

export default Testimonial;
